import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['textRevealEncourage', 'textRevealListen'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const TextReveal = makeShortcode("TextReveal");
const GoTo = makeShortcode("GoTo");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "things-arent-okay--what-should-i-do"
    }}>{`Things aren’t okay – what should I do?`}</h1>
    <p>{`If you have concerns about your teenager’s mood or behaviour…`}</p>
    <Grid container spacing={3} mdxType="Grid">
  <Grid item xs={12} sm={6} mdxType="Grid">
    <TextReveal id="textRevealEncourage" header="Encourage your teenager to talk about what is on their mind (click to reveal)" mdxType="TextReveal">
      <p>
        Choose an appropriate time and place to talk with your teenager. Let them know that you’ve noticed some changes
        in them and that you’re concerned and want to help. Ask them if they’d like to chat about this.
      </p>
      <p>
        If they feel comfortable talking to you, ask them in a non-judgemental way how they are feeling and how long
        it’s been going on for.
      </p>
      <p>
        If they don’t feel comfortable talking to you, encourage them to discuss how they are feeling with someone else.
      </p>
      <p>
        (For helpful tips on talking with your teenager, see the <GoTo to="/dashboard" mdxType="GoTo"><i>Connect</i></GoTo> module).
      </p>
    </TextReveal>
  </Grid>
  <Grid item xs={12} sm={6} mdxType="Grid">
    <TextReveal id="textRevealListen" header="Really listen to what they are saying (click to reveal)" mdxType="TextReveal">
      <p>
        Do your best to listen and understand how things are for your teenager – give them your unconditional support.
        Don’t worry about saying ‘the right thing’.
      </p>
    </TextReveal>
  </Grid>
    </Grid>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m10/48-01-new.svg" alt="child on stairs" mdxType="SingleImage" />
    <p><strong parentName="p">{`Check`}</strong>{` if the change in their mood or behaviour is due to a temporary situation (e.g. upcoming exams) or is more of an ongoing problem.`}</p>
    <p><strong parentName="p">{`Find out`}</strong>{` if the change in your teenager’s mood or behaviour is affecting other parts of their life (e.g. school work, relationships, work, sport).`}</p>
    <p>{`Seek help from a trained mental health professional if problems persist.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      